<template>
  <div>
       <div class="breadcrumbs" data-aos="fade-in">
            <div class="container">
                <h2>{{$t("application-form")}}</h2>
            </div>
        </div>
        <section class="contact">
            <div class="container col-md-12" data-aos="fade-up">
                <div class="d-flex justify-content-center row mt-5">
                    <div class="col-lg-12 mt-5 mt-lg-0">
                        <div class="d-flex justify-content-center mb-3 register-form">
                            <button class="get-started-btn" style="background-color: blue;" @click.prevent="goToReview" type="submit">{{$t("review-and-submit")}}</button>
                        </div>

                        <leader-information />

                        <team-members />

                         <organizations />

                        <project-detail />
                        
                        <i-p-r />

                        <others />

                    </div>
                    <div class="d-flex justify-content-center register-form">
                        <button class="get-started-btn" style="background-color: blue;" @click.prevent="goToReview" type="submit">{{$t("review-and-submit")}}</button>
                    </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>

import LeaderInformation from '../components/application/LeaderInformation.vue';
import TeamMembers from '../components/application/TeamMembers.vue';
import ProjectDetail from '../components/application/ProjectDetail.vue';
import IPR from '../components/application/IPR.vue';
import Others from '../components/application/Others.vue';
import Organizations from '../components/application/Organizations.vue';

export default {
    components : {
        LeaderInformation, 
        TeamMembers,
        ProjectDetail,
        IPR,
        Others,
        Organizations
     },
   methods : {
    goToReview(){
        this.$router.push({name : "Review"});
    }
   },
    created(){
       this.$http.get('/innovations/latest')
       .then(response => {
        localStorage.setItem("innovation_id", response.data.message.id)
       }).catch(e => {
        console.log(e)
       })
    },
    mounted(){
        this.$http.get("/get-consent")
        .then(response => {
            if(!response.data.success){
                this.$router.push({name : "Consent"});
            }
        }).catch(e => {
            console.log(e)
        })
    }
    
}
</script>



