var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-3"},[_c('h5',{staticClass:"card-header"},[_vm._v(_vm._s(_vm.$t("details-of-other-organizations-involved-in-the-research")))]),_c('validation-observer',{ref:"verifyForm"},[_c('form',{staticClass:"register-form",on:{"submit":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}}},[_c('div',{staticClass:"card-body"},[_vm._l((_vm.form.organizations),function(organization,o){return _c('div',{key:("organization-" + o),staticClass:"card mb-3"},[_c('div',{staticClass:"card-header"},[_c('h6',[_vm._v(_vm._s(_vm.$t("organization-number", {number : ("# " + (o+1))})))])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"organization_name"}},[_vm._v(_vm._s(_vm.$t("organization"))+" "),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"required","name":"name of organization","vid":"name of organization"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(organization.name),expression:"organization.name"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"text","id":"organization_name","name":"name of organization","placeholder":_vm.$t('organization-name')},domProps:{"value":(organization.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(organization, "name", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"address"}},[_vm._v(_vm._s(_vm.$t("permanent-address"))),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"required","name":"address","vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(organization.address),expression:"organization.address"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"rows":"5","id":"address","name":"address","placeholder":_vm.$t('permanent-address')},domProps:{"value":(organization.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(organization, "address", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"url"}},[_vm._v(_vm._s(_vm.$t("website"))+" ")])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"url","name":"website","vid":"website"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(organization.website),expression:"organization.website"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"text","id":"website","name":"website","placeholder":_vm.$t('website')},domProps:{"value":(organization.website)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(organization, "website", $event.target.value)}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)})],1)]),_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-header"},[_c('h6',[_vm._v(_vm._s(_vm.$t("contact-person-detail")))])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"leader_prefix"}},[_vm._v(_vm._s(_vm.$t("prefix"))+" "),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"required","name":"prefix","vid":"prefix"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(organization.contactPrefix),expression:"organization.contactPrefix"}],staticClass:"form-control form-select",class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"leader_prefix","aria-label":_vm.$t('choose-prefix')},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(organization, "contactPrefix", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":"","value":""}},[_vm._v(_vm._s(_vm.$t("choose-prefix")))]),_c('option',{attrs:{"value":"dr"}},[_vm._v(_vm._s(_vm.$t("dr")))]),_c('option',{attrs:{"value":"mr"}},[_vm._v(_vm._s(_vm.$t("mr")))]),_c('option',{attrs:{"value":"ms"}},[_vm._v(_vm._s(_vm.$t("ms")))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"surname"}},[_vm._v(_vm._s(_vm.$t("surname"))+" "),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"required","name":"surname","vid":"surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(organization.contactSurname),expression:"organization.contactSurname"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"text","id":"surname","name":"surname","placeholder":_vm.$t('surname')},domProps:{"value":(organization.contactSurname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(organization, "contactSurname", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"names"}},[_vm._v(_vm._s(_vm.$t("names"))+" "),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"required","name":"names","vid":"names"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(organization.contactNames),expression:"organization.contactNames"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"text","id":"names","name":"names","placeholder":_vm.$t('names')},domProps:{"value":(organization.contactNames)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(organization, "contactNames", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("email"))),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(organization.contactEmail),expression:"organization.contactEmail"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"email","id":"email","name":"email","placeholder":_vm.$t('email')},domProps:{"value":(organization.contactEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(organization, "contactEmail", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"phone"}},[_vm._v(_vm._s(_vm.$t("phone"))),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"required","name":"phone","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"mode":"international","inputOptions":{placeholder:_vm.$t('enter-phone-number')},"aria-label":_vm.$t('enter-phone-number')},model:{value:(organization.contactPhone),callback:function ($$v) {_vm.$set(organization, "contactPhone", $$v)},expression:"organization.contactPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])]),_c('div',{staticClass:"card-footer d-flex justify-content-end"},[_c('div',{staticClass:"d-flex justify-content-end col-md-6"},[(organization.id)?_c('button',{staticClass:"get-started-btn",staticStyle:{"background-color":"red"},attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.deleteOrganization(organization.id, o)}}},[_vm._v(_vm._s(_vm.$t("delete")))]):_c('button',{staticClass:"get-started-btn",staticStyle:{"background-color":"red"},attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.removeOrganization(o)}}},[_vm._v(_vm._s(_vm.$t("delete")))])])])])}),_c('div',{staticClass:"d-flex justify-content-start col-md-6"},[_c('button',{staticClass:"get-started-btn",staticStyle:{"background-color":"blue"},attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addOrganization.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("add-new-organization")))])])],2),_c('div',{staticClass:"d-flex justify-content-end my-3 mx-3"},[_c('div',{staticClass:"d-flex justify-content-end col-md-6"},[_c('button',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("submit")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }