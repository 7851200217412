export default {
    data: [
        {
            en: "Burkina Faso",
            fr: "Burkina Faso",
            aces: [
              {
                acronym: "CEA/ITECH-MTV",
                centre:
                  "Centre d’Excellence Africain et Innovation Biotechnologiques pour l’Elimination des Maladies a Transmission Vectorielle",
              },
              {
                acronym: "CEA-2IE",
                centre:
                  "Centre d’Excellence pour la Formation et la Recherche en Sciences et Technologies de l’Eau, l’Energie et l’Environnement en Afrique de l’Ouest et du Centre",
              },
              {
                acronym: "CEFORGRIS",
                centre:
                  "Centre d'Etudes, de Formation et de Recherche en Gestion des Risques Sociaux",
              },
              {
                acronym: "CFOREM",
                centre:
                  "Centre de Formation, de Recherche et d’Expertises en Sciences du Medicament",
              },
              {
                acronym: "CoE_2iE",
                centre:
                  "Collège d'Ingénerie: Institut International de l'Eau et de l'Environnement",
              },
            ],
          },
          {
            en: "Benin",
            fr: "Bénin",
            aces: [
              {
                acronym: "C2EA",
                centre: "Centre d’Excellence Africain pour l'Eau et l'Assainissement",
              },
              {
                acronym: "CEA-SMIA",
                centre: "CEA en Sciences Mathématiques, Informatique et Applications",
              },
              {
                acronym: "CoE_EIE",
                centre: "Ecole d'ingénieurs, l’Université d’Abomey Calavi, Benin",
              },
            ],
          },
          {
            en: "Côte d'Ivoire",
            fr: "Côte d'Ivoire",
            aces: [
              {
                acronym: "CCBAD",
                centre:
                  "Changements Climatiques, la Biodiversite et l’agriculture Durable, Cote d’Ivoire",
              },
              {
                acronym: "CEA-MEM",
                centre: "Mines et Environment Minier",
              },
              {
                acronym: "ENSEA",
                centre: "Statistique et Economie Quantitative",
              },
              {
                acronym: "VALOPRO",
                centre: "Valorisation des Dechets en Produits a Haute Valeur Ajoutee",
              },
            ],
          },
          {
            en: "Djibouti",
            fr: "Djibouti",
            aces: [
              {
                acronym: "CEALT",
                centre: "Logistique et transport",
              },
              {
                acronym: "CoE_Djibouti",
                centre: "UD Ecole d'ingénieurs",
              },
            ],
          },
          {
            en: "Ghana",
            fr: "Ghana",
            aces: [
              {
                acronym: "ACECoR",
                centre: "Africa Centre of Excellence in Coastal Resilience",
              },
              {
                acronym: "CoE-KEEP",
                centre: "KNUST Engineering Education Project",
              },
              {
                acronym: "RCEES",
                centre: "Regional Center for Energy and Environmental Sustainability",
              },
              {
                acronym: "RWESCK",
                centre: "Regional Water and Environmental Sanitation Centre Kumasi",
              },
              {
                acronym: "TRECK",
                centre: "Regional Transport Research and Education Centre",
              },
              {
                acronym: "WACCBIP",
                centre: "Cell Biology of Infectious and Non-Communicable Diseases",
              },
              {
                acronym: "WACCI",
                centre: "West Africa Centre for Crop Improvement",
              },
              {
                acronym: "WACWISA",
                centre:
                  "West African Center for Water, Irrigation and Sustainable Agriculture",
              },
              {
                acronym: "WAGMC",
                centre: "West Africa Genetic Medicine Centre",
              },
            ],
          },
          {
            en: "Gambia",
            fr: "Gambie",
            aces: [
              {
                acronym: "STEE",
                centre: "Science, Technology and Engineering for Entrepreneurship",
              },
            ],
          },
          {
            en: "Guinea",
            fr: "Guinée",
            aces: [
              {
                acronym: "CEA PCMT",
                centre:
                  "Centre d’Excellence Africain pour la Prevention et le Controle des Maladies Transmissibles",
              },
              {
                acronym: "CEMS",
                centre: "Centre Emergéant Mines et Sociétés",
              },
            ],
          },
          {
            en: "Niger",
            fr: "Niger",
            aces: [
              {
                acronym: "CERPP",
                centre:
                  "Centre d’Excellence Regional sur les Productions Pastorales : Viande, Lait, Cuirs et Peaux",
              },
              {
                acronym: "EMIG",
                centre: "Environement minier",
              },
              {
                acronym: "MS4SSA",
                centre:
                  "Innovative Teaching/Learning of Mathematics and the Sciences for Sub-Saharan Africa",
              },
            ],
          },
          {
            en: "Nigeria",
            fr: "Nigeria",
            aces: [
              {
                acronym: "ACE_SPED",
                centre: "Sustainable Power and Energy Development",
              },
              {
                acronym: "ACEDHARS",
                centre:
                  "Drug Research, Herbal Medicine Development and Regulatory Science",
              },
              {
                acronym: "ACE-FUELS",
                centre: "Future Energies and Electrochemical Systems",
              },
              {
                acronym: "ACEGID",
                centre: "Genomics of Infectious Diseases",
              },
              {
                acronym: "ACEITSE",
                centre: "Innovation and Transformations in STEM Education",
              },
              {
                acronym: "ACEMFS",
                centre: "Mycotoxin and Food Safety",
              },
              {
                acronym: "ACENPEE",
                centre: "New Pedagogy in Engineering Education",
              },
              {
                acronym: "ACENTDFB",
                centre: "Neglected Tropical Diseases and Forensic Biotechnology",
              },
              {
                acronym: "ACEPHAP",
                centre: "Population Health and Policy",
              },
              {
                acronym: "ACE-PUTOR",
                centre: "Public Health and Toxicological Research",
              },
              {
                acronym: "ACETEL",
                centre: "Technology Enhanced Learning",
              },
              {
                acronym: "CAPiC",
                centre: "Applied Informatics and Communication",
              },
              {
                acronym: "CDA",
                centre: "Dryland Agriculture",
              },
              {
                acronym: "CEFOR",
                centre: "Oilfield Chemicals Research",
              },
              {
                acronym: "CEFTER",
                centre: "Food Technology and Research",
              },
              {
                acronym: "CERHI",
                centre: "Reproductive Health Innovation",
              },
              {
                acronym: "OAU-OAK",
                centre: "ICT-Driven Knowledge Park",
              },
            ],
          },
          {
            en: "Senegal",
            fr: "Sénégal",
            aces: [
              {
                acronym: "CEA-AGIR",
                centre: "CEA-Agir sur l'Environnement et la Santé",
              },
              {
                acronym: "CEA-AGRISAN",
                centre: "Agriculture Pour La Securite Alimentaire Et Nutritionnelle",
              },
              {
                acronym: "CEA-MITIC",
                centre:
                  "Centre d’Excellence Africain Mathématiques, Informatique et Tic",
              },
              {
                acronym: "CEA-SAMEF",
                centre:
                  "Centre d’Excellence Africaine pour la Sante de la Mere et de l’Enfant",
              },
            ],
          },
          {
            id: 768,
            en: "Togo",
            fr: "Togo",
            aces: [
              {
                acronym: "CEA CERViDA DOUNEDON",
                centre: "Centre d’Excellence Regional Villes Durables en Afrique",
              },
              {
                acronym: "CERME",
                centre:
                  "Centre d’Excellence Regional pour la Maitrise de l’Electricite",
              },
              {
                acronym: "CERSA",
                centre: "Centre d’Excellence Regional sur les Sciences Aviaires",
              },
            ],
          }
    ]
}