<template>
  <div class="card mb-3">
    <h5 class="card-header">{{$t("lead-applicants-information")}}</h5>
    <div class="card-body">
         <validation-observer ref="verifyForm">
            <form role="form" class="register-form" @submit.prevent="send">

                <div class="row">
                    <div class="col-lg-2 form-group">
                        <label for="leader_prefix">{{$t("prefix")}} <small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-10 form-group">
                        <validation-provider rules="required" name="prefix" #default="{ errors }" vid="prefix">
                            <select v-model="form.prefix" class="form-control form-select" :class="errors.length > 0 ? 'is-invalid':null" id="leader_prefix" :aria-label="$t('choose-prefix')" >
                                <option selected disabled value="">{{$t("choose-prefix")}}</option>
                                <option value="dr">{{$t("dr")}}</option>
                                <option value="mr">{{$t("mr")}}</option>
                                <option value="ms">{{$t("ms")}}</option>
                            </select>
                            <small class="text-danger">{{errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-2 form-group">
                        <label for="leader_surname">{{$t("surname")}} <small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-10 form-group">
                        <validation-provider rules="required" name="surname" #default="{ errors }" vid="surname">
                            <input type="text" id="surname" v-model="form.surname" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="surname" :placeholder="$t('surname')"/>
                            <small class="text-danger">{{errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                        <div class="col-lg-2 form-group">
                            <label for="names">{{$t("names")}} <small class="text-danger">*</small></label>
                        </div>
                        <div class="col-lg-10 form-group">
                            <validation-provider rules="required" name="names" #default="{ errors }" vid="names">
                                <input type="text" id="names" v-model="form.names" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="names" :placeholder="$t('names')"/>
                                <small class="text-danger">{{errors[0] }}</small>
                            </validation-provider>
                        </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-2 form-group">
                        <label for="mail">{{$t("email")}} <small class="text-danger">*</small></label>
                    </div>
                    
                    <div class="col-lg-10 form-group">
                         <validation-provider rules="required|email" name="email" #default="{ errors }" vid="email">
                            <input type="email" id="email" v-model="form.email" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="email" :placeholder="$t('email')"/>
                            <small class="text-danger">{{errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-2 form-group">
                        <label for="phone">{{$t("phone")}} <small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-10 form-group">
                        <validation-provider rules="required" name="phone" #default="{ errors }" vid="phone">
                            <vue-tel-input class="form-control" :class="errors.length > 0 ? 'is-invalid':null" v-model="form.phone" mode="international" :inputOptions="{placeholder:$t('enter-phone-number')}" :aria-label="$t('enter-phone-number')"></vue-tel-input>
                            <small class="text-danger">{{errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-2 form-group">
                        <label for="address">{{$t("postal-physical-address")}} <small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-10 form-group">
                        <validation-provider rules="required" name="address" #default="{ errors }" vid="address">
                            <textarea cols="5" id="address" v-model="form.address" class="form-control" :class="errors.length > 0 ? 'is-invalid':null" :placeholder="$t('postal-physical-address')" ></textarea>
                            <small class="text-danger">{{errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-2 form-group">
                        <label for="country">{{$t("country")}}</label>
                    </div>
                    <div class="col-lg-10 form-group">
                    <select class="form-control form-select" v-model="form.country" id="country" :aria-label="$t('select-country')" >
                        <option v-for="(country, c) in countries" :value="country.en" :key="`country-${c}`">{{country[$i18n.locale]}}</option>
                    </select>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-2 form-group">
                        <label for="leader_centre">{{$t("centre")}} <small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-10 form-group">
                        <validation-provider rules="required" name="centre" #default="{ errors }" vid="centre">
                            <select class="form-control form-select" :class="errors.length > 0 ? 'is-invalid':null" id="country" v-model="form.centre"  :aria-label="$t('select-centre')" >
                                <option value="" selected disabled>{{$t('select-centre')}}</option>
                                <option  v-for="(ace, a) in filteredAces" :value="ace.centre" :key="`ace-${a}`">{{ace.centre}}</option>
                            </select>
                            <small class="text-danger">{{errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-1 d-flex justify-content-end">
                    <div class="d-flex justify-content-end col-lg-6 primary-button">
                        <button type="submit">{{$t("submit")}}</button>
                    </div>
                </div>
            </form>
         </validation-observer>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { VueTelInput } from "vue-tel-input";
import aces from "../../data/aces";
import { ValidationProvider, ValidationObserver} from 'vee-validate';
import { required } from '../../utils/validations/validations'
import {toast} from "../../utils/alert.util";

export default {
    components : {
        VueTelInput,
        ValidationProvider,
        ValidationObserver,
    },
    data(){
        return {
            form : {
                country : "Nigeria",
                prefix : "",
                applicant_id : localStorage.getItem("applicant_id"),
                innovation_id : localStorage.getItem("innovation_id")
            },
            countries : aces.data,
            aces : {},
            required,
            errors : {}
        }
    },
    computed : {
        filteredAces(){
            // const country = this.form.country.toLowerCase();
            return this.countries.find(country => country.en === this.form.country).aces;
            // return this.countries.filter(country => country.en.toLowerCase().includes(aces) )
        }
    },
    methods : {
        async getSelectedCountry(){
            // this.form.centre = "",
            // this.form.country = this.form.country ? this.form.country : "Nigeria";
            this.aces =  this.countries.find(country => country.en === this.form.country).aces;
      },
      send(){
        this.$refs.verifyForm.validate().then(success => {
            if (success) {
                const loading = this.$vs.loading({
                color : "#47b2e4",
                type : "circles",
                text : this.$t("processing-please-wait")
                });
                this.$http.post(`/leader_infos`, this.form)
                .then(response => {
                    this.form = response.data.message;
                    toast.show({
                        icon : "success",
                        title : this.$t("submitted-successfully"),
                    })
                    loading.close();
                }).catch(e => {
                    loading.close();
                    switch (e.response.status) {
                        case 422:
                        this.errors = e.response.data.errors;
                        toast.show({
                            icon : "error",
                            title : this.$t('fields-incorrectly-filled')
                        });
                        break;
                    
                        default:
                        toast.show({
                            icon : "error",
                            title : this.$t("something-went-wrong", {code : e.response.status}),
                        });
                        break;
                    }
                })
            }
            else {
                toast.show({
                    icon : "error",
                    title : this.$t('fields-incorrectly-filled')
                });
            }
        })
        },
        getLeader(){
            this.$http.get(`/leader_infos`)
            .then(response => {
                if(response.data.message){
                    this.form = response.data.message
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },
    created(){
        this.getLeader();
        // this.getSelectedCountry();
        
    }
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>