var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-3"},[_c('h5',{staticClass:"card-header"},[_vm._v(_vm._s(_vm.$t("description-of-the-project")))]),_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"verifyForm"},[_c('form',{staticClass:"register-form",on:{"submit":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v(_vm._s(_vm.$t("project-title"))+" "),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"max:255|required","name":"title","vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"text","id":"title","name":"title","placeholder":_vm.$t('project-title')},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "title", $event.target.value)}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}])})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"thematic_area"}},[_vm._v(_vm._s(_vm.$t("research-area-field-branch"))+" ")])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"required","name":"thematic_area","vid":"thematic_area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.thematicArea),expression:"form.thematicArea"}],staticClass:"form-control form-select",class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"thematic-area","aria-label":_vm.$t('choose-thematic-area')},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "thematicArea", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":"","value":""}},[_vm._v(_vm._s(_vm.$t("choose-thematic-area")))]),_c('option',{attrs:{"value":"stem"}},[_vm._v(_vm._s(_vm.$t("stem")))]),_c('option',{attrs:{"value":"agriculture"}},[_vm._v(_vm._s(_vm.$t("agriculture")))]),_c('option',{attrs:{"value":"health"}},[_vm._v(_vm._s(_vm.$t("health")))]),_c('option',{attrs:{"value":"environment"}},[_vm._v(_vm._s(_vm.$t("environment")))]),_c('option',{attrs:{"value":"applied-social-sciences-and-education"}},[_vm._v(_vm._s(_vm.$t("applied-social-sciences-and-education")))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"key_words"}},[_vm._v(_vm._s(_vm.$t("key-words-text"))+" "),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"max-words:5|required","name":"key_words","vid":"key_words"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.keyWords),expression:"form.keyWords"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"text","id":"key_words","name":"key_words","placeholder":_vm.$t('key-words-text')},domProps:{"value":(_vm.form.keyWords)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "keyWords", $event.target.value)}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}])})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"rationale"}},[_vm._v(_vm._s(_vm.$t("rationale-and-background-text"))+" ("),_c('b',[_vm._v(_vm._s(_vm.$t("character-max-4000")))]),_vm._v(")"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"max:4000|required","name":"rationale","vid":"rationale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:errors.length > 0 ? 'is-invalid form-control':null,attrs:{"id":"rationale","options":{theme:'snow', placeholder: _vm.$t('enter-text-here')}},model:{value:(_vm.form.rationale),callback:function ($$v) {_vm.$set(_vm.form, "rationale", $$v)},expression:"form.rationale"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticClass:"col-md-6 d-flex justify-content-end"},[_c('small',[_vm._v(_vm._s(_vm.characterCount("rationale"))+" "+_vm._s(_vm.$t('characters')))])])])]}}])})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"objective"}},[_vm._v(_vm._s(_vm.$t("overall-objective-text"))+" ("),_c('b',[_vm._v(_vm._s(_vm.$t("character-max-4000")))]),_vm._v(")"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"max:4000|required","name":"objective","vid":"objective"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:errors.length > 0 ? 'is-invalid form-control':null,attrs:{"id":"objective","options":{theme:'snow', placeholder: _vm.$t('enter-text-here')}},model:{value:(_vm.form.objective),callback:function ($$v) {_vm.$set(_vm.form, "objective", $$v)},expression:"form.objective"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticClass:"col-md-6 d-flex justify-content-end"},[_c('small',[_vm._v(_vm._s(_vm.characterCount("objective"))+" "+_vm._s(_vm.$t('characters')))])])])]}}])})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"objective"}},[_vm._v(_vm._s(_vm.$t("solution-text"))+" ("),_c('b',[_vm._v(_vm._s(_vm.$t("character-max-4000")))]),_vm._v(")"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"max:4000|required","name":"solution","vid":"solution"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:errors.length > 0 ? 'is-invalid form-control':null,attrs:{"id":"solution","options":{theme:'snow', placeholder: _vm.$t('enter-text-here')}},model:{value:(_vm.form.solution),callback:function ($$v) {_vm.$set(_vm.form, "solution", $$v)},expression:"form.solution"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticClass:"col-md-6 d-flex justify-content-end"},[_c('small',[_vm._v(_vm._s(_vm.characterCount("solution"))+" "+_vm._s(_vm.$t('characters')))])])])]}}])})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"objective"}},[_vm._v(_vm._s(_vm.$t("results-text"))+" ("),_c('b',[_vm._v(_vm._s(_vm.$t("character-max-4000")))]),_vm._v(")"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"max:4000|required","name":"results","vid":"results"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:errors.length > 0 ? 'is-invalid form-control':null,attrs:{"id":"results","options":{theme:'snow', placeholder: _vm.$t('enter-text-here')}},model:{value:(_vm.form.results),callback:function ($$v) {_vm.$set(_vm.form, "results", $$v)},expression:"form.results"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticClass:"col-md-6 d-flex justify-content-end"},[_c('small',[_vm._v(_vm._s(_vm.characterCount("results"))+" "+_vm._s(_vm.$t('characters')))])])])]}}])})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"objective"}},[_vm._v(_vm._s(_vm.$t("benefits-text"))+" ("),_c('b',[_vm._v(_vm._s(_vm.$t("character-max-4000")))]),_vm._v(")"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"max:4000|required","name":"benefits","vid":"benefits"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:errors.length > 0 ? 'is-invalid form-control':null,attrs:{"id":"benefits","options":{theme:'snow', placeholder: _vm.$t('enter-text-here')}},model:{value:(_vm.form.benefits),callback:function ($$v) {_vm.$set(_vm.form, "benefits", $$v)},expression:"form.benefits"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticClass:"col-md-6 d-flex justify-content-end"},[_c('small',[_vm._v(_vm._s(_vm.characterCount("benefits"))+" "+_vm._s(_vm.$t('characters')))])])])]}}])})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"objective"}},[_vm._v(_vm._s(_vm.$t("scalability-text"))+" ("),_c('b',[_vm._v(_vm._s(_vm.$t("character-max-4000")))]),_vm._v(")"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"max:4000|required","name":"scalability","vid":"scalability"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:errors.length > 0 ? 'is-invalid form-control':null,attrs:{"id":"scalability","options":{theme:'snow', placeholder: _vm.$t('enter-text-here')}},model:{value:(_vm.form.scalability),callback:function ($$v) {_vm.$set(_vm.form, "scalability", $$v)},expression:"form.scalability"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticClass:"col-md-6 d-flex justify-content-end"},[_c('small',[_vm._v(_vm._s(_vm.characterCount("scalability"))+" "+_vm._s(_vm.$t('characters')))])])])]}}])})],1)]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-lg-12 form-group"},[_c('label',{attrs:{"for":"objective"}},[_vm._v(_vm._s(_vm.$t("literature-references"))+" ("),_c('b',[_vm._v(_vm._s(_vm.$t("character-max-4000")))]),_vm._v(")"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-lg-12 form-group"},[_c('validation-provider',{attrs:{"rules":"max:4000|required","name":"references","vid":"references"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:errors.length > 0 ? 'is-invalid form-control':null,attrs:{"id":"references","options":{theme:'snow', placeholder: _vm.$t('enter-text-here')}},model:{value:(_vm.form.references),callback:function ($$v) {_vm.$set(_vm.form, "references", $$v)},expression:"form.references"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticClass:"col-md-6 d-flex justify-content-end"},[_c('small',[_vm._v(_vm._s(_vm.characterCount("references"))+" "+_vm._s(_vm.$t('characters')))])])])]}}])})],1)]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"d-flex justify-content-end col-md-6"},[_c('button',{staticClass:"danger-btn",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("submit")))])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }