<template>
  <div class="card mb-3">
    <h5 class="card-header">{{$t("details-of-other-organizations-involved-in-the-research")}}</h5>
    <validation-observer ref="verifyForm">
        <form @submit.prevent="send" class="register-form">
            <div class="card-body">
                <div class="card mb-3" v-for="(organization, o) in form.organizations" :key="`organization-${o}`">
                    <div class="card-header">
                        <h6>{{$t("organization-number", {number : `# ${o+1}`})}}</h6>
                    </div>
                    <div class="card-body">
                        <div class="row my-3">
                            <div class="col-lg-2 form-group">
                                <label for="organization_name">{{$t("organization")}} <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="required" name="name of organization" #default="{ errors }" vid="name of organization">
                                    <input type="text" id="organization_name" v-model="organization.name" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="name of organization" :placeholder="$t('organization-name')"/>
                                    <small class="text-danger">{{errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="row my-3">
                            <div class="col-lg-2 form-group">
                                <label for="address">{{$t("permanent-address")}}<small class="text-danger">*</small></label>
                            </div>
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="required" name="address" #default="{ errors }" vid="address">
                                    <textarea rows="5" id="address" v-model="organization.address" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="address" :placeholder="$t('permanent-address')"></textarea>
                                    <small class="text-danger">{{errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="row my-3">
                            <div class="col-lg-2 form-group">
                                <label for="url">{{$t("website")}} </label>
                            </div>
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="url" name="website" #default="{ errors }" vid="website">
                                    <input type="text" id="website" v-model="organization.website" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="website" :placeholder="$t('website')"/>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <small class="text-danger">{{errors[0] }}</small>
                                        </div>
                                    </div>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="card-header">
                                <h6>{{$t("contact-person-detail")}}</h6>
                            </div>
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-2 form-group">
                                        <label for="leader_prefix">{{$t("prefix")}} <small class="text-danger">*</small></label>
                                    </div>
                                    <div class="col-lg-10 form-group">
                                        <validation-provider rules="required" name="prefix" #default="{ errors }" vid="prefix">
                                            <select v-model="organization.contactPrefix" class="form-control form-select" :class="errors.length > 0 ? 'is-invalid':null" id="leader_prefix" :aria-label="$t('choose-prefix')" >
                                                <option selected disabled value="">{{$t("choose-prefix")}}</option>
                                                <option value="dr">{{$t("dr")}}</option>
                                                <option value="mr">{{$t("mr")}}</option>
                                                <option value="ms">{{$t("ms")}}</option>
                                            </select>
                                            <small class="text-danger">{{errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>

                                <div class="row my-3">
                                    <div class="col-lg-2 form-group">
                                        <label for="surname">{{$t("surname")}} <small class="text-danger">*</small></label>
                                    </div>
                                    <div class="col-lg-10 form-group">
                                        <validation-provider rules="required" name="surname" #default="{ errors }" vid="surname">
                                            <input type="text" id="surname" v-model="organization.contactSurname" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="surname" :placeholder="$t('surname')"/>
                                            <small class="text-danger">{{errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>

                                <div class="row my-3">
                                    <div class="col-lg-2 form-group">
                                        <label for="names">{{$t("names")}} <small class="text-danger">*</small></label>
                                    </div>
                                    <div class="col-lg-10 form-group">
                                        <validation-provider rules="required" name="names" #default="{ errors }" vid="names">
                                            <input type="text" id="names" v-model="organization.contactNames" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="names" :placeholder="$t('names')"/>
                                            <small class="text-danger">{{errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>

                                <div class="row my-3">
                                    <div class="col-lg-2 form-group">
                                        <label for="email">{{$t("email")}}<small class="text-danger">*</small></label>
                                    </div>
                                    
                                    <div class="col-lg-10 form-group">
                                        <validation-provider rules="required|email" name="email" #default="{ errors }" vid="email">
                                            <input type="email" id="email" v-model="organization.contactEmail" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="email" :placeholder="$t('email')"/>
                                            <small class="text-danger">{{errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>

                                <div class="row my-3">
                                    <div class="col-lg-2 form-group">
                                        <label for="phone">{{$t("phone")}}<small class="text-danger">*</small></label>
                                    </div>
                                    <div class="col-lg-10 form-group">
                                        <validation-provider rules="required" name="phone" #default="{ errors }" vid="phone">
                                            <vue-tel-input class="form-control" :class="errors.length > 0 ? 'is-invalid':null" v-model="organization.contactPhone" mode="international" :inputOptions="{placeholder:$t('enter-phone-number')}" :aria-label="$t('enter-phone-number')"></vue-tel-input>
                                            <small class="text-danger">{{errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                        <div class="d-flex justify-content-end col-md-6">
                            <button v-if="organization.id" type="submit" class="get-started-btn" style="background-color: red;" @click.prevent="deleteOrganization(organization.id, o)">{{$t("delete")}}</button>
                            <button v-else type="submit" class="get-started-btn" style="background-color: red;" @click.prevent="removeOrganization(o)">{{$t("delete")}}</button>
                        </div>
                    </div>
                </div>
                
                <div class="d-flex justify-content-start col-md-6">
                    <button class="get-started-btn" style="background-color: blue;" type="submit" @click.prevent="addOrganization">{{$t("add-new-organization")}}</button>
                </div>
            </div>
            <div class="d-flex justify-content-end my-3 mx-3">
                <div class="d-flex justify-content-end col-md-6">
                    <button type="submit">{{$t("submit")}}</button>
                </div>
            </div>
        </form>
    </validation-observer>
  </div>
</template>

<script>
// import axios from "axios";
import { VueTelInput } from "vue-tel-input";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '../../utils/validations/validations'
import {toast} from "../../utils/alert.util";
export default {
    components : {
        VueTelInput,
        ValidationProvider,
        ValidationObserver,
    },
    data(){
        return {
            required,
            form : {
                organizations : []
            },
        }
    },
    methods : {
        send(){
        this.$refs.verifyForm.validate().then(success => {
            if (success) {
                const loading = this.$vs.loading({
                color : "#47b2e4",
                type : "circles",
                text : this.$t("processing-please-wait")
                });
                this.$http.post(`/organizations`, this.form)
                .then(response => {
                    this.form.organizations = response.data.message;
                    toast.show({
                        icon : "success",
                        title : this.$t("submitted-successfully"),
                    })
                    loading.close();
                }).catch(e => {
                    loading.close();
                    switch (e.response.status) {
                        case 422:
                        this.errors = e.response.data.errors;
                        toast.show({
                            icon : "error",
                            title : this.$t('fields-incorrectly-filled')
                        });
                        break;

                        default:
                        toast.show({
                            icon : "error",
                            title : this.$t("something-went-wrong", {code : e.response.status}),
                        });
                        break;
                    }
                })
            }
            else {
                toast.show({
                    icon : "error",
                    title : this.$t('fields-incorrectly-filled')
                });
            }
        })
        },
        addOrganization(){
            this.form.organizations.push({
                id : "",
            });
        },
        removeOrganization(i){
            this.form.organizations.splice(i, 1)
        },
        deleteOrganization(id, i){
            const loading = this.$vs.loading({
                color : "#47b2e4",
                type : "circles",
                text : this.$t("processing-please-wait")
            });
            this.$http.delete(`/organizations/${id}`)
            .then(response => {
                this.form.organizations.splice(i, 1)
                toast.show({
                    icon : "success",
                    title : this.$t(response.data.message),
                })
                loading.close();
            }).catch(e => {
                loading.close();
                console.log(e)
            })
        },
        getOrganizations(){
        this.$http.get(`/organizations`)
            .then(response => {
                if(response.data.message){
                    this.form.organizations = response.data.message
                    console.log(response.data.message)
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },
    created(){
        this.getOrganizations();
    }
}
</script>

<style>

</style>