<template>
  <div class="card mb-3">
    <h5 class="card-header">{{$t("description-of-the-project")}}</h5>
    <div class="card-body">
        <validation-observer ref="verifyForm">
            <form @submit.prevent="send" class="register-form">

                <div class="row">
                    <div class="col-lg-12 form-group">
                        <label for="title">{{$t("project-title")}} <small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max:255|required" name="title" #default="{ errors }" vid="title">
                            <input type="text" id="title" v-model="form.title" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="title" :placeholder="$t('project-title')"/>
                            <div class="row">
                                <div class="col-md-12">
                                    <small class="text-danger">{{errors[0] }}</small>
                                </div>
                            </div>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-12 form-group">
                         <label for="thematic_area">{{$t("research-area-field-branch")}} </label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="required" name="thematic_area" #default="{ errors }" vid="thematic_area">
                             <select v-model="form.thematicArea" class="form-control form-select" :class="errors.length > 0 ? 'is-invalid':null" id="thematic-area" :aria-label="$t('choose-thematic-area')" >
                                <option selected disabled value="">{{$t("choose-thematic-area")}}</option>
                                <option value="stem">{{$t("stem")}}</option>
                                <option value="agriculture">{{$t("agriculture")}}</option>
                                <option value="health">{{$t("health")}}</option>
                                <option value="environment">{{$t("environment")}}</option>
                                <option value="applied-social-sciences-and-education">{{$t("applied-social-sciences-and-education")}}</option>
                            </select>
                            <small class="text-danger">{{errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 form-group">
                        <label for="key_words">{{$t("key-words-text")}} <small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max-words:5|required" name="key_words" #default="{ errors }" vid="key_words">
                            <input type="text" id="key_words" v-model="form.keyWords" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="key_words" :placeholder="$t('key-words-text')"/>
                            <div class="row">
                                <div class="col-md-12">
                                    <small class="text-danger">{{errors[0] }}</small>
                                </div>
                            </div>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-12 form-group">
                        <label for="rationale">{{$t("rationale-and-background-text")}}  (<b>{{$t("character-max-4000")}}</b>)<small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max:4000|required" name="rationale" #default="{ errors }" vid="rationale">
                            <quill-editor  :class="errors.length > 0 ? 'is-invalid form-control':null" id="rationale" v-model="form.rationale" :options="{theme:'snow', placeholder: $t('enter-text-here')}" />
                           <div class="row">
                            <div class="col-md-6">
                                 <small class="text-danger">{{errors[0] }}</small>
                            </div>
                             <div class="col-md-6 d-flex justify-content-end">
                                 <small>{{characterCount("rationale") }} {{$t('characters')}}</small>
                            </div>
                           </div>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-12 form-group">
                        <label for="objective">{{$t("overall-objective-text")}}  (<b>{{$t("character-max-4000")}}</b>)<small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max:4000|required" name="objective" #default="{ errors }" vid="objective">
                            <quill-editor  :class="errors.length > 0 ? 'is-invalid form-control':null" id="objective" v-model="form.objective" :options="{theme:'snow', placeholder: $t('enter-text-here')}" />
                           <div class="row">
                            <div class="col-md-6">
                                 <small class="text-danger">{{errors[0] }}</small>
                            </div>
                             <div class="col-md-6 d-flex justify-content-end">
                                 <small>{{characterCount("objective") }} {{$t('characters')}}</small>
                            </div>
                           </div>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-12 form-group">
                        <label for="objective">{{$t("solution-text")}}  (<b>{{$t("character-max-4000")}}</b>)<small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max:4000|required" name="solution" #default="{ errors }" vid="solution">
                            <quill-editor  :class="errors.length > 0 ? 'is-invalid form-control':null" id="solution" v-model="form.solution" :options="{theme:'snow', placeholder: $t('enter-text-here')}" />
                           <div class="row">
                            <div class="col-md-6">
                                 <small class="text-danger">{{errors[0] }}</small>
                            </div>
                             <div class="col-md-6 d-flex justify-content-end">
                                 <small>{{characterCount("solution") }} {{$t('characters')}}</small>
                            </div>
                           </div>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-12 form-group">
                        <label for="objective">{{$t("results-text")}}  (<b>{{$t("character-max-4000")}}</b>)<small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max:4000|required" name="results" #default="{ errors }" vid="results">
                            <quill-editor  :class="errors.length > 0 ? 'is-invalid form-control':null" id="results" v-model="form.results" :options="{theme:'snow', placeholder: $t('enter-text-here')}" />
                           <div class="row">
                            <div class="col-md-6">
                                 <small class="text-danger">{{errors[0] }}</small>
                            </div>
                             <div class="col-md-6 d-flex justify-content-end">
                                 <small>{{characterCount("results") }} {{$t('characters')}}</small>
                            </div>
                           </div>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-12 form-group">
                        <label for="objective">{{$t("benefits-text")}}  (<b>{{$t("character-max-4000")}}</b>)<small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max:4000|required" name="benefits" #default="{ errors }" vid="benefits">
                            <quill-editor  :class="errors.length > 0 ? 'is-invalid form-control':null" id="benefits" v-model="form.benefits" :options="{theme:'snow', placeholder: $t('enter-text-here')}" />
                           <div class="row">
                            <div class="col-md-6">
                                 <small class="text-danger">{{errors[0] }}</small>
                            </div>
                             <div class="col-md-6 d-flex justify-content-end">
                                 <small>{{characterCount("benefits") }} {{$t('characters')}}</small>
                            </div>
                           </div>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-12 form-group">
                        <label for="objective">{{$t("scalability-text")}}  (<b>{{$t("character-max-4000")}}</b>)<small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max:4000|required" name="scalability" #default="{ errors }" vid="scalability">
                            <quill-editor  :class="errors.length > 0 ? 'is-invalid form-control':null" id="scalability" v-model="form.scalability" :options="{theme:'snow', placeholder: $t('enter-text-here')}" />
                           <div class="row">
                            <div class="col-md-6">
                                 <small class="text-danger">{{errors[0] }}</small>
                            </div>
                             <div class="col-md-6 d-flex justify-content-end">
                                 <small>{{characterCount("scalability") }} {{$t('characters')}}</small>
                            </div>
                           </div>
                        </validation-provider>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-lg-12 form-group">
                        <label for="objective">{{$t("literature-references")}}  (<b>{{$t("character-max-4000")}}</b>)<small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max:4000|required" name="references" #default="{ errors }" vid="references">
                            <quill-editor  :class="errors.length > 0 ? 'is-invalid form-control':null" id="references" v-model="form.references" :options="{theme:'snow', placeholder: $t('enter-text-here')}" />
                           <div class="row">
                            <div class="col-md-6">
                                 <small class="text-danger">{{errors[0] }}</small>
                            </div>
                             <div class="col-md-6 d-flex justify-content-end">
                                 <small>{{characterCount("references") }} {{$t('characters')}}</small>
                            </div>
                           </div>
                        </validation-provider>
                    </div>
                </div>

                <!-- <div class="row my-3">
                    <div class="col-lg-12 form-group">
                        <label for="objective">{{$t("mitigation-text")}}  (<b>{{$t("character-max-4000")}}</b>)<small class="text-danger">*</small></label>
                    </div>
                    <div class="col-lg-12 form-group">
                        <validation-provider rules="max:4000|required" name="mitigation" #default="{ errors }" vid="mitigation">
                            <quill-editor  :class="errors.length > 0 ? 'is-invalid form-control':null" id="mitigation" v-model="form.mitigation" :options="{theme:'snow', placeholder: $t('enter-text-here')}" />
                            <div class="row">
                                <div class="col-md-6">
                                    <small class="text-danger">{{errors[0] }}</small>
                                </div>
                                <div class="col-md-6 d-flex justify-content-end">
                                    <small>{{characterCount("mitigation") }} {{$t('characters')}}</small>
                                </div>
                            </div>
                        </validation-provider>
                    </div>
                </div> -->

                <div class="d-flex justify-content-end">
                    <div class="d-flex justify-content-end col-md-6">
                        <button type="submit" class="danger-btn">{{$t("submit")}}</button>
                    </div>
                </div>
            </form>
        </validation-observer>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '../../utils/validations/validations'
import {toast} from "../../utils/alert.util";
export default {
    components : {
        quillEditor,
        ValidationProvider,
        ValidationObserver
        },
    data(){
        return {
            form : {
                title : "",
                thematicArea : "",
                rationale : "",
                objective : "",
                solution : "",
                results : "",
                benefits : "",
                scalability : "",
                references : "",
                mitigation : "not-set",
            },
            required
        }
    },
    computed : {
        
    },
    methods : {
         characterCount(input){
             return this.form[input].replace(/(<([^>]+)>)/gi, "").length
        },
         send(){
            this.$refs.verifyForm.validate().then(success => {
            if (success) {
                const loading = this.$vs.loading({
                color : "#47b2e4",
                type : "circles",
                text : this.$t("processing-please-wait")
                });
                // this.form.applicant_id = 1;
                this.$http.post(`/project_infos`, this.form)
                .then(response => {
                    this.form = response.data.message;
                    toast.show({
                        icon : "success",
                        title : this.$t("submitted-successfully"),
                    })
                    loading.close();
                }).catch(e => {
                    loading.close();
                    switch (e.response.status) {
                        case 422:
                        this.errors = e.response.data.errors;
                        toast.show({
                            icon : "error",
                            title : this.$t('fields-incorrectly-filled')
                        });
                        break;
                    
                        default:
                        toast.show({
                            icon : "error",
                            title : this.$t("something-went-wrong", {code : e.response.status}),
                        });
                        break;
                    }
                })
            }
            else {
                toast.show({
                    icon : "error",
                    title : this.$t('fields-incorrectly-filled')
                });
            }
        })
        },

        getDetail(){
            this.$http.get(`/project_infos`)
            .then(response => {
                 if(response.data.message){
                    this.form = response.data.message
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },

    created(){

        this.getDetail()
    }
}
</script>

<style lang="css">
/* @import 'quill/dist/quill.core.css' */
</style>