<template>
     <div class="card mb-3">
        <h5 class="card-header">{{$t("other-relevant-information")}}</h5>
        <div class="card-body">
            <validation-observer ref="verifyForm">
                <form @submit.prevent="send" class="register-form">
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <label for="annexes">{{$t("annexes-text")}}  (<b>{{$t("character-max-4000")}}</b>)</label>
                        </div>
                        <div class="col-lg-12 form-group">
                            <validation-provider rules="max:4000" name="annexes" #default="{ errors }" vid="annexes">
                                <quill-editor  :class="errors.length > 0 ? 'is-invalid form-control':null" id="annexes" v-model="form.annexes" :options="{theme:'snow', placeholder: $t('enter-text-here')}" />
                                <div class="row">
                                    <div class="col-md-6">
                                        <small class="text-danger">{{errors[0] }}</small>
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-end">
                                        <small>{{characterCount("annexes") }} {{$t('characters')}}</small>
                                    </div>
                                </div>
                            </validation-provider>
                        </div>
                    </div>
                    
                    <div class="row my-3">
                        <div class="col-lg-12 form-group">
                            <label for="url">{{$t("url-text")}} <small class="text-danger">*</small></label>
                        </div>
                        <div class="col-lg-12 form-group">
                            <validation-provider rules="url|required" name="url" #default="{ errors }" vid="url">
                                <input type="text" id="url" v-model="form.url" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="url" :placeholder="$t('url-text')"/>
                                <div class="row">
                                    <div class="col-md-12">
                                        <small class="text-danger">{{errors[0] }}</small>
                                    </div>
                                </div>
                            </validation-provider>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="d-flex justify-content-end col-md-6">
                            <button type="submit" class="danger-btn">{{$t("submit")}}</button>
                        </div>
                    </div>
                </form>
            </validation-observer>
        </div>
     </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '../../utils/validations/validations'
import {toast} from "../../utils/alert.util";

export default {
    components : {
        quillEditor,
        ValidationProvider,
        ValidationObserver
    },

    data(){
        return {
            required,
            form : {
                annexes : "",
                url : "",
            }
        }
    },
    methods : {
         characterCount(input){
             return this.form[input].replace(/(<([^>]+)>)/gi, "").length
        },
        send(){
            this.$refs.verifyForm.validate().then(success => {
                if (success) {
                    const loading = this.$vs.loading({
                    color : "#47b2e4",
                    type : "circles",
                    text : this.$t("processing-please-wait")
                    });
                    this.$http.post(`/other_infos`, this.form)
                    .then(response => {
                        this.form = response.data.message;
                        toast.show({
                            icon : "success",
                            title : this.$t("submitted-successfully"),
                        })
                        loading.close();
                    }).catch(e => {
                        loading.close();
                        switch (e.response.status) {
                            case 422:
                            this.errors = e.response.data.errors;
                            toast.show({
                                icon : "error",
                                title : this.$t('fields-incorrectly-filled')
                            });
                            break;
                        
                            default:
                            toast.show({
                                icon : "error",
                                title : this.$t("something-went-wrong", {code : e.response.status}),
                            });
                            break;
                        }
                    })
                }
                else {
                    toast.show({
                        icon : "error",
                        title : this.$t('fields-incorrectly-filled')
                    });
                }
            })
        },

        getOtherInfo(){
            this.$http.get(`/other_infos`)
            .then(response => {
                if(response.data.message){
                    this.form = response.data.message
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },
    created(){
        this.getOtherInfo();
    }
}
</script>

<style>

</style>