export default {
    data: [
        {
            acronym: "CEA/ITECH-MTV",
            name:
                "Centre d’Excellence Africain et Innovation Biotechnologiques pour l’Elimination des Maladies a Transmission Vectorielle",
        },
        {
            acronym: "CEA-2IE",
            name:
                "Centre d’Excellence pour la Formation et la Recherche en Sciences et Technologies de l’Eau, l’Energie et l’Environnement en Afrique de l’Ouest et du Centre",
        },
        {
            acronym: "CEFORGRIS",
            name:
                "Centre d'Etudes, de Formation et de Recherche en Gestion des Risques Sociaux",
        },
        {
            acronym: "CFOREM",
            name:
                "Centre de Formation, de Recherche et d’Expertises en Sciences du Medicament",
        },
        {
            acronym: "CoE_2iE",
            name:
                "Collège d'Ingénerie: Institut International de l'Eau et de l'Environnement",
        },
        {
            acronym: "C2EA",
            name: "Centre d’Excellence Africain pour l'Eau et l'Assainissement",
        },
        {
            acronym: "CEA-SMIA",
            name: "CEA en Sciences Mathématiques, Informatique et Applications",
        },
        {
            acronym: "CoE_EIE",
            name: "Ecole d'ingénieurs, l’Université d’Abomey Calavi, Benin",
        },
        {
            acronym: "CCBAD",
            name:
                "Changements Climatiques, la Biodiversite et l’agriculture Durable, Cote d’Ivoire",
        },
        {
            acronym: "CEA-MEM",
            name: "Mines et Environment Minier",
        },
        {
            acronym: "ENSEA",
            name: "Statistique et Economie Quantitative",
        },
        {
            acronym: "VALOPRO",
            name: "Valorisation des Dechets en Produits a Haute Valeur Ajoutee",
        },
        {
            acronym: "CEALT",
            name: "Logistique et transport",
        },
        {
            acronym: "CoE_Djibouti",
            name: "UD Ecole d'ingénieurs",
        },
        {
            acronym: "ACECoR",
            name: "Africa Centre of Excellence in Coastal Resilience",
        },
        {
            acronym: "CoE-KEEP",
            name: "KNUST Engineering Education Project",
        },
        {
            acronym: "RCEES",
            name: "Regional Center for Energy and Environmental Sustainability",
        },
        {
            acronym: "RWESCK",
            name: "Regional Water and Environmental Sanitation Centre Kumasi",
        },
        {
            acronym: "TRECK",
            name: "Regional Transport Research and Education Centre",
        },
        {
            acronym: "WACCBIP",
            name: "Cell Biology of Infectious and Non-Communicable Diseases",
        },
        {
            acronym: "WACCI",
            name: "West Africa Centre for Crop Improvement",
        },
        {
            acronym: "WACWISA",
            name:
                "West African Center for Water, Irrigation and Sustainable Agriculture",
        },
        {
            acronym: "WAGMC",
            name: "West Africa Genetic Medicine Centre",
        },
        {
            acronym: "STEE",
            name: "Science, Technology and Engineering for Entrepreneurship",
        },
        {
            acronym: "CEA PCMT",
            name:
                "Centre d’Excellence Africain pour la Prevention et le Controle des Maladies Transmissibles",
        },
        {
            acronym: "CEMS",
            name: "Centre Emergéant Mines et Sociétés",
        },
        {
            acronym: "CERPP",
            name:
                "Centre d’Excellence Regional sur les Productions Pastorales : Viande, Lait, Cuirs et Peaux",
        },
        {
            acronym: "EMIG",
            name: "Environement minier",
        },
        {
            acronym: "MS4SSA",
            name:
                "Innovative Teaching/Learning of Mathematics and the Sciences for Sub-Saharan Africa",
        },
        {
            acronym: "ACE_SPED",
            name: "Sustainable Power and Energy Development",
        },
        {
            acronym: "ACEDHARS",
            name:
                "Drug Research, Herbal Medicine Development and Regulatory Science",
        },
        {
            acronym: "ACE-FUELS",
            name: "Future Energies and Electrochemical Systems",
        },
        {
            acronym: "ACEGID",
            name: "Genomics of Infectious Diseases",
        },
        {
            acronym: "ACEITSE",
            name: "Innovation and Transformations in STEM Education",
        },
        {
            acronym: "ACEMFS",
            name: "Mycotoxin and Food Safety",
        },
        {
            acronym: "ACENPEE",
            name: "New Pedagogy in Engineering Education",
        },
        {
            acronym: "ACENTDFB",
            name: "Neglected Tropical Diseases and Forensic Biotechnology",
        },
        {
            acronym: "ACEPHAP",
            name: "Population Health and Policy",
        },
        {
            acronym: "ACE-PUTOR",
            name: "Public Health and Toxicological Research",
        },
        {
            acronym: "ACETEL",
            name: "Technology Enhanced Learning",
        },
        {
            acronym: "CAPiC",
            name: "Applied Informatics and Communication",
        },
        {
            acronym: "CDA",
            name: "Dryland Agriculture",
        },
        {
            acronym: "CEFOR",
            name: "Oilfield Chemicals Research",
        },
        {
            acronym: "CEFTER",
            name: "Food Technology and Research",
        },
        {
            acronym: "CERHI",
            name: "Reproductive Health Innovation",
        },
        {
            acronym: "OAU-OAK",
            name: "ICT-Driven Knowledge Park",
        },
        {
            acronym: "CEA-AGIR",
            name: "CEA-Agir sur l'Environnement et la Santé",
        },
        {
            acronym: "CEA-AGRISAN",
            name: "Agriculture Pour La Securite Alimentaire Et Nutritionnelle",
        },
        {
            acronym: "CEA-MITIC",
            name:
                "Centre d’Excellence Africain Mathématiques, Informatique et Tic",
        },
        {
            acronym: "CEA-SAMEF",
            name:
                "Centre d’Excellence Africaine pour la Sante de la Mere et de l’Enfant",
        },
        {
            acronym: "CEA CERViDA DOUNEDON",
            name: "Centre d’Excellence Regional Villes Durables en Afrique",
        },
        {
            acronym: "CERME",
            name:
                "Centre d’Excellence Regional pour la Maitrise de l’Electricite",
        },
        {
            acronym: "CERSA",
            name: "Centre d’Excellence Regional sur les Sciences Aviaires",
        },
    ]
}