<template>
  <div class="card mb-3">
    <h5 class="card-header">{{$t("co-researchers-information")}}</h5>
    <validation-observer ref="verifyForm">
        <form @submit.prevent="send" class="register-form">
            <div class="card-body">
                <div class="card mb-3" v-for="(member, m) in form.members" :key="`member-${m}`">
                    <div class="card-header">
                        <h6>{{$t("co-researcher-no", {number : `# ${m+1}`})}}</h6>
                    </div>
                    <div class="card-body">
                        
                    
                        <div class="row">
                            <div class="col-lg-2 form-group">
                                <label for="member_prefix">{{$t("prefix")}} <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="required" name="prefix" #default="{ errors }" vid="prefix">
                                    <select v-model="member['prefix']" class="form-control form-select" :class="errors.length > 0 ? 'is-invalid':null" id="member_prefix" :aria-label="$t('choose-prefix')" >
                                        <option selected disabled value="">{{$t("choose-prefix")}}</option>
                                        <option value="dr">{{$t("dr")}}</option>
                                        <option value="mr">{{$t("mr")}}</option>
                                        <option value="ms">{{$t("ms")}}</option>
                                    </select>
                                    <small class="text-danger">{{errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="row my-3">
                            <div class="col-lg-2 form-group">
                                <label for="surname">{{$t("surname")}} <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="required" name="surname" #default="{ errors }" vid="surname">
                                    <input type="text" id="surname" v-model="member.surname" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="surname" :placeholder="$t('surname')"/>
                                    <small class="text-danger">{{errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="row my-3">
                            <div class="col-lg-2 form-group">
                                <label for="names">{{$t("names")}} <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="required" name="names" #default="{ errors }" vid="names">
                                    <input type="text" id="names" v-model="member.names" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="names" :placeholder="$t('names')"/>
                                    <small class="text-danger">{{errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="row my-3">
                            <div class="col-lg-2 form-group">
                                <label for="mail">{{$t("email")}} <small class="text-danger">*</small></label>
                            </div>
                            
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="required|email" name="email" #default="{ errors }" vid="email">
                                    <input type="email" id="email" v-model="member.email" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="email" :placeholder="$t('email')"/>
                                    <small class="text-danger">{{errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                         <div class="row my-3">
                            <div class="col-lg-2 form-group">
                                <label for="phone">{{$t("phone")}} <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="required" name="phone" #default="{ errors }" vid="phone">
                                    <vue-tel-input class="form-control" :class="errors.length > 0 ? 'is-invalid':null" v-model="member.phone" mode="international" :inputOptions="{placeholder:$t('enter-phone-number')}" :aria-label="$t('enter-phone-number')"></vue-tel-input>
                                    <small class="text-danger">{{errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                         <div class="row my-3">
                            <div class="col-lg-2 form-group">
                                <label for="address">{{$t("postal-physical-address")}} <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="required" name="address" #default="{ errors }" vid="address">
                                    <textarea cols="5" id="address" v-model="member.address" class="form-control" :class="errors.length > 0 ? 'is-invalid':null" :placeholder="$t('postal-physical-address')" ></textarea>
                                    <small class="text-danger">{{errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>


                        <div class="row my-3">
                            <div class="col-lg-2 form-group">
                                <label for="centre">{{$t("centre")}} <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-lg-10 form-group">
                                <validation-provider rules="required" name="centre" #default="{ errors }" vid="centre">
                                    <select class="form-control form-select" :class="errors.length > 0 ? 'is-invalid':null" id="centre" v-model="member.centre" :aria-label="$t('select-centre')" >
                                        <option value="" selected disabled>{{$t('select-centre')}}</option>
                                        <option  v-for="(centre, c) in centres" :value="centre.name" :key="`ace-${c}`">{{centre.name}} ({{centre.acronym}})</option>
                                    </select>
                                    <small class="text-danger">{{errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer d-flex justify-content-end">
                        <div class="d-flex justify-content-end col-md-6">
                            <button v-if="member.id" type="submit" class="get-started-btn" style="background-color: red;" @click.prevent="deleteMember(member.id, m)">{{$t("delete")}}</button>
                            <button v-else type="submit" class="get-started-btn" style="background-color: red;" @click.prevent="removeMember(m)">{{$t("delete")}}</button>
                        </div>
                    </div>
                </div>
                
                <div class="d-flex justify-content-start col-md-6">
                    <button class="get-started-btn" style="background-color: blue;" type="submit" @click.prevent="addMember" v-if="form.members.length < 2">{{$t("add-new-member")}}</button>
                </div>
            </div>
            <div class="d-flex justify-content-end my-3 mx-3">
                <div class="d-flex justify-content-end col-md-6">
                    <button type="submit">{{$t("submit")}}</button>
                </div>
            </div>
        </form>
    </validation-observer>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '../../utils/validations/validations'
import {toast} from "../../utils/alert.util";
import centres from "../../data/centres";
export default {
    components : {
        VueTelInput,
        ValidationProvider,
        ValidationObserver,
    },
    data(){
        return {
            required,
            form : {
                members : []
            },
            centres : centres.data
        }
    },
    methods : {
        send(){
        this.$refs.verifyForm.validate().then(success => {
            if (success) {
                const loading = this.$vs.loading({
                    color : "#47b2e4",
                    type : "circles",
                    text : this.$t("processing-please-wait")
                });
                this.$http.post(`/member_infos`, this.form)
                .then(response => {
                    // this.getMembers();
                    this.form.members = response.data.message;
                    toast.show({
                        icon : "success",
                        title : this.$t("submitted-successfully"),
                    })
                    loading.close();
                }).catch(e => {
                    loading.close();
                    switch (e.response.status) {
                        case 422:
                        this.errors = e.response.data.errors;
                        toast.show({
                            icon : "error",
                            title : this.$t('fields-incorrectly-filled')
                        });
                        break;
                    
                        default:
                        toast.show({
                            icon : "error",
                            title : this.$t("something-went-wrong", {code : e.response.status}),
                        });
                        break;
                    }
                })
            }
            else {
                toast.show({
                    icon : "error",
                    title : this.$t('fields-incorrectly-filled')
                });
            }
        })
        },
        addMember(){
            this.form.members.push({
                id : "",
                applicant_id : localStorage.getItem("applicant_id"),
                innovation_id : localStorage.getItem("innovation_id")
            });
        },
        removeMember(i){
            this.form.members.splice(i, 1)
        },

      getMembers(){
        this.$http.get(`/member_infos`)
            .then(response => {
                if(response.data.message){
                    this.form.members = response.data.message
                }
            }).catch(e => {
                console.log(e)
            })
        },

        deleteMember(id, i){
            const loading = this.$vs.loading({
                color : "#47b2e4",
                type : "circles",
                text : this.$t("processing-please-wait")
            });
            this.$http.delete(`/member_infos/${id}`)
            .then(response => {
                this.form.members.splice(i, 1)
                toast.show({
                    icon : "success",
                    title : this.$t(response.data.message),
                })
                loading.close();
            }).catch(e => {
                loading.close();
                console.log(e)
            })
        }
    },
    created(){
        this.getMembers();
    }
}
</script>

<style>

</style>